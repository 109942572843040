import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CTAStatement from '../components/Statement/ctaStatement'
import CategoryStatement from '../components/Statement/categoryStatement'
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import Calculator from '../components/calculator/secretCalculator';

const SecretCalc = ({ data }) => {

    const subheader = "Calculate earnings"

    const SEO = {
        pageInfo: "Ntheos Mag | Super Secret Earnings Calculator"
    }

    return (
        <Layout seo={SEO}>
            <CategoryStatement subheader={subheader} />

            <Section bg='white' type="cta"
                id='carousel' size='medium'>
                <Calculator />

            </Section>

        </Layout>
    )
};



export default SecretCalc;

